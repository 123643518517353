import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Newsletter from "../components/newsletter"
import IconProfile from "../../static/icons/profile.svg";
import IconTurkey from "../../static/icons/flag-turkey.svg";
import IconGermany from "../../static/icons/flag-germany.svg";
import IconSweden from "../../static/icons/flag-sweden.svg";
import IconSpain from "../../static/icons/flag-spain.svg";
import IconNetherlands from "../../static/icons/flag-netherlands.svg";
import IconEurope from "../../static/icons/flag-europe.svg";
import IconSwitzerland from "../../static/icons/flag-switzerland.svg";
import EnvelopeSVG from '../images/icons/envelope-open.svg'

const NewsPage = () => (
  <Layout>
    <Seo title="About us" description="The consortium bringing their unique set of expertise to build a strong, focused team and working on the ECO Qube." />
    <div className="max-w-screen-lg mx-auto px-4 relative z-20 mt-20">
      <div className="max-w-screen-md mx-auto text-center">
        <div className="inline-flex justify-center items-center">
          <div className="relative inline-block mr-6">
            <img src={'../cube-skelleton.svg'} className="w-16" />
            <IconProfile className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 -mt-1" />
          </div>
          <h2 className="text-4xl lg:text-5xl font-bold font-display text-center">About</h2>
        </div>
        <p className="mt-4 text-center text-2xl">The consortium bringing their unique set of expertise to build a strong, focused team and working on the ECO Qube.</p>
      </div>
      <div className="grid md:grid-cols-2 gap-8 lg:gap-12 mt-12">
        <div className="grid rounded bg-white p-6 text-layout-gray-base">
          <div>
            <img src={'../logos/helio.png'} className="h-12" />
            <p className="font-bold text-2xl mt-4">HELIO AG</p>
            <div className="inline-flex items-center mt-1">
              <IconSwitzerland />
              <span className="ml-2 text-layout-gray-700">Switzerland</span>
            </div>
            <p className="mt-4">Helio is a Swiss technology startup that builds and provides a technology platform & software that allows for intelligent workload scheduling.</p>
            <p className="mt-4">It can connect to any data centre with available computing resources and deploy any workload to almost anywhere in the world. Of course, this is not always feasible or efficient. Therefore, the Helio platform includes various measures to make intelligent decisions about which workload should be placed onto which hardware at which time to achieve optimal efficiency.</p>
          </div>
          <div className="self-end">
            <hr className="my-6 border-layout-gray-300" />
            <div className="flex items-start">
              <div className="h-16 w-16 min-w-max rounded-full mr-4 overflow-hidden">
                <img
                  alt="Contact Image"
                  className="h-16 w-16 object-cover object-top"
                  src="/about-images/christoph.jpg"
                />
              </div>
              <div>
                <h3 className="font-semibold text-base leading-4">
                  Christoph Buchli
                </h3>
                <div className="text-layout-gray-500 text-sm mt-1">
                  WP4 Leader: Smart workload orchestration and airflow management
                </div>
                <div className="flex mt-2">
                  <EnvelopeSVG className="h-5 w-5 mr-2" />
                  <a
                    className="block text-sm underline"
                    href="mailto:christoph@helio.exchange"
                  >
                    christoph@helio.exchange
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="grid rounded bg-white p-6 text-layout-gray-base">
          <div>
            <img src={'../logos/blockheating.png'} className="h-12" />
            <p className="font-bold text-2xl mt-4">BLOCKHEATING BV</p>
            <div className="inline-flex items-center mt-1">
              <IconSweden />
              <span className="ml-2 text-layout-gray-700">Netherlands</span>
            </div>
            <p className="mt-4">Blockheating believes that residual energy should not be wasted. Blockheating offers green and CO2 neutral bare metal hosting in one of their green datacenters in the Netherlands. Hosted on reliable servers with a tailor made solution to re-use the residual heat from the IT hardware. Next to that Blockheating offers residual heat for small and medium size greenhouses.</p>
          </div>
          <div className="self-end">
            <hr className="my-6 border-layout-gray-300" />
            <div className="flex items-start">
              <div className="h-16 w-16 min-w-max rounded-full mr-4 overflow-hidden">
                <img
                  alt="Contact Image"
                  className="h-16 w-16 object-cover object-top"
                  src="/about-images/jeroen-burks.jpeg"
                />
              </div>
              <div>
                <h3 className="font-semibold text-base leading-4">
                  Jeroen Burks
                </h3>
                <div className="text-layout-gray-500 text-sm mt-1">
                  Datacenter pilot - Netherlands
                </div>
                <div className="flex mt-2">
                  <EnvelopeSVG className="h-5 w-5 mr-2" />
                  <a
                    className="block text-sm underline"
                    href="mailto:jeroen@blockheating.com"
                  >
                    jeroen@blockheating.com
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="grid rounded bg-white p-6 text-layout-gray-base">
          <div>
            <img src={'../logos/empa.png'} className="h-12" />
            <p className="font-bold text-2xl mt-4">EMPA</p>
            <div className="inline-flex items-center mt-1">
              <IconSwitzerland />
              <span className="ml-2 text-layout-gray-700">Switzerland</span>
            </div>
            <p className="mt-4">The Urban Energy Systems Laboratory focuses on the development of methods, strategies and solutions to transform buildings, neighborhoods and cities into energy efficient and decarbonized systems. With our research we seek for solutions that significantly contribute in reaching national and global emission targets. Our core competences lie in the modelling, design and assessment of building and urban systems with focus on energy hubs, multi-energy grids, and integration of renewable energy and storage systems.</p>
          </div>
          <div className="self-end">
            <hr className="my-6 border-layout-gray-300" />
            <div className="flex items-start">
              <div>
                <h3 className="font-semibold text-base leading-4">
                  Sascha Stoller
                </h3>
                <div className="text-layout-gray-500 text-sm mt-1">
                  Datacenter pilot - Switzerland
                </div>
                <div className="flex mt-2">
                  <EnvelopeSVG className="h-5 w-5 mr-2" />
                  <a
                    className="block text-sm underline"
                    href="mailto:sascha.stoller@empa.ch"
                  >
                    sascha.stoller@empa.ch
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="grid rounded bg-white p-6 text-layout-gray-base">
          <div>
            <img src={'../logos/lande.png'} className="h-12" />
            <p className="font-bold text-2xl mt-4">LANDE</p>
            <div className="inline-flex items-center mt-1">
              <IconTurkey />
              <span className="ml-2 text-layout-gray-700">Turkey</span>
            </div>
            <p className="mt-4">LANDE is a manufacturer of ICT ecosystem components such as rack enclosures and accessories, telecom field cabinets (indoor/outdoor), server and data centre cabinets, PDUs and a wide range of further products for ICT sector.</p>
            <p className="mt-4">LANDE has fully functioning R&D team qualified for project management. Our team consists of experts with international project management experience and academic consultants from various universities. Our R&D division can provide the flexibility for designing, developing and prototyping. This is our greatest asset which gives us opportunity to create exclusive projects better than the other manufacturers focused on the end users.</p>
          </div>
          <div className="self-end">
            <hr className="my-6 border-layout-gray-300" />
            <div className="flex items-start">
              <div className="h-16 w-16 min-w-max rounded-full mr-4 overflow-hidden">
                <img
                  alt="Contact Image"
                  className="h-16 w-16 object-cover object-top"
                  src="/about-images/cagatay-yilmaz.jpg"
                />
              </div>
              <div>
                <h3 className="font-semibold text-base leading-4">
                  Çağatay Yılmaz
                </h3>
                <div className="text-layout-gray-500 text-sm mt-1">
                  Project Coordinator
                </div>
                <div className="flex mt-2">
                  <EnvelopeSVG className="h-5 w-5 mr-2" />
                  <a
                    className="block text-sm underline"
                    href="mailto:cagatay.yilmaz@lande.com.tr"
                  >
                    cagatay.yilmaz@lande.com.tr
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="grid rounded bg-white p-6 text-layout-gray-base">
          <div>
            <img src={'../logos/lulea.png'} className="h-12" />
            <p className="font-bold text-2xl mt-4">LTU</p>
            <div className="inline-flex items-center mt-1">
              <IconSweden />
              <span className="ml-2 text-layout-gray-700">Sweden</span>
            </div>
            <p className="mt-4">Luleå University of Technology (LTU) is renowned for applied research in close collaboration with national and international companies and stakeholders. The yearly turnover is around 160 million Euros, of which 90 are related to research activities. LTU has 1600 employees and 17000 students distributed over 4 campuses, and has several strategic partners including both leading universities (e.g., Stanford and Monash Universities) and multi-national corporations (e.g., ABB, Ericsson, Scania, Shell, IBM, Volvo Aero, and LKAB)</p>
            <p className="mt-4">The developed expertise comprises modelling, optimization and control of complex and large-scale systems, with a special focus on energy system and heating and cooling systems in particular. </p>
          </div>
          <div className="self-end">
            <hr className="my-6 border-layout-gray-300" />
            <div className="flex items-start">
              <div className="h-16 w-16 min-w-max rounded-full mr-4 overflow-hidden">
                <img
                  alt="Contact Image"
                  className="h-16 w-16 object-cover object-top"
                  src="/about-images/amirreza-zaman.jpg"
                />
              </div>
              <div>
                <h3 className="font-semibold text-base leading-4">
                  Amirreza Zaman
                </h3>
                <div className="text-layout-gray-500 text-sm mt-1">
                  WP5 Leader: User context, socio-economic analysis
                </div>
                <div className="flex mt-2">
                  <EnvelopeSVG className="h-5 w-5 mr-2" />
                  <a
                    className="block text-sm underline"
                    href="mailto:amirreza.zaman@ltu.se"
                  >
                    amirreza.zaman@ltu.se
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="grid rounded bg-white p-6 text-layout-gray-base">
          <div>
            <img src={'../logos/rm-solution.png'} className="h-12" />
            <p className="font-bold text-2xl mt-4">R2M</p>
            <div className="inline-flex items-center mt-1">
              <IconSpain />
              <span className="ml-2 text-layout-gray-700">Spain</span>
            </div>
            <p className="mt-4">R2M Solution. is an integrated and multi-disciplinary entrepreneurial innovation company that aggressively targets filling the gap between research activities and market implementation across the fields of Innovation, Engineering, Energy Services & Sustainability and ICT/Automation. R2M is a strategic innovator itself and as part of its business model helps organizations and projects plan and execute the strategic use research funding carried out over a comprehensive development strategy from idea to market. In doing so, R2M provides leadership, links high performance exploitation-oriented networks, and leverages public and private funding instruments.</p>
          </div>
          <div className="self-end">
            <hr className="my-6 border-layout-gray-300" />
            <div className="flex items-start">
              <div className="h-16 w-16 min-w-max rounded-full mr-4 overflow-hidden">
                <img
                  alt="Contact Image"
                  className="h-16 w-16 object-cover object-top"
                  src="/about-images/luis-febres.png"
                />
              </div>
              <div>
                <h3 className="font-semibold text-base leading-4">
                  Luis Febres
                </h3>
                <div className="text-layout-gray-500 text-sm mt-1">
                  WP7 Leader: Exploitation and uptake
                </div>
                <div className="flex mt-2">
                  <EnvelopeSVG className="h-5 w-5 mr-2" />
                  <a
                    className="block text-sm underline"
                    href="mailto:eco-qube@r2msolution.com"
                  >
                    eco-qube@r2msolution.com
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="grid rounded bg-white p-6 text-layout-gray-base">
          <div>
            <img src={'../logos/dstech.png'} className="h-12" />
            <p className="font-bold text-2xl mt-4">D&S TECH</p>
            <div className="inline-flex items-center mt-1">
              <IconTurkey />
              <span className="ml-2 text-layout-gray-700">Turkey</span>
            </div>
            <p className="mt4">Computational methods are at the heart of many fields of engineering and sciences. With the advances made in computer hardware and software technologies the use of this technique is gaining more and more importance in the analysis and evaluation of very complex problems. One of these fields, is the fluid dynamics field which has the widest application range, and covers an area from space technologies to aerospace industry.</p>
          </div>
          <div className="self-end">
            <hr className="my-6 border-layout-gray-300" />
            <div className="flex items-start">
              <div className="h-16 w-16 min-w-max rounded-full mr-4 overflow-hidden">
                <img
                  alt="Contact Image"
                  className="h-16 w-16 object-cover object-top"
                  src="/about-images/demirel-profil.jpg"
                />
              </div>
              <div>
                <h3 className="font-semibold text-base leading-4">
                  Ender Demirel
                </h3>
                <div className="text-layout-gray-500 text-sm mt-1">
                  WP2 Leader: Dynamic cooling design concept based on open source CFD model
                </div>
                <div className="flex mt-2">
                  <EnvelopeSVG className="h-5 w-5 mr-2" />
                  <a
                    className="block text-sm underline"
                    href="mailto:edemirel1977@gmail.com"
                  >
                    edemirel1977@gmail.com
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="grid rounded bg-white p-6 text-layout-gray-base">
          <div>
            <img src={'../logos/endoks.png'} className="h-12" />
            <p className="font-bold text-2xl mt-4">ENDOKS Energy</p>
            <div className="inline-flex items-center mt-1">
              <IconTurkey />
              <span className="ml-2 text-layout-gray-700">Turkey</span>
            </div>
            <p className="mt-4">ENDOKS is at the forefront of researching, developing and delivering smart energy management solutions for energy systems of all scales – utilities, industry and end-users.</p>
            <p className="mt-4">ENDOKS has been offering a complete performance monitoring/management system for solar power plants (from small rooftop applications up to large scale systems). Thanks to ENDOKS’s R&D teams, ENDOKS is capable to offer not only the software (as SaaS), but also its own hardware products as a bundled solution with a perfect harmony. This helps ENDOKS to develop flexible, efficient and cost-competitive solutions for all scale of PV plants.</p>
          </div>
          <div className="self-end">
            <hr className="my-6 border-layout-gray-300" />
            <div className="flex items-start">
              <div className="h-16 w-16 min-w-max rounded-full mr-4 overflow-hidden">
                <img
                  alt="Contact Image"
                  className="h-16 w-16 object-cover object-top"
                  src="/about-images/canan-sisman-korkmaz.png"
                />
              </div>
              <div>
                <h3 className="font-semibold text-base leading-4">
                  Canan Şişman Korkmaz
                </h3>
                <div className="text-layout-gray-500 text-sm mt-1">
                  WP3 Leader: Smart energy management system
                </div>
                <div className="flex mt-2">
                  <EnvelopeSVG className="h-5 w-5 mr-2" />
                  <a
                    className="block text-sm underline"
                    href="mailto:canan.korkmaz@endoks.com"
                  >
                    canan.korkmaz@endoks.com
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="grid rounded bg-white p-6 text-layout-gray-base">
          <div>
            <img src={'../logos/greenit.png'} className="h-12" />
            <p className="font-bold text-2xl mt-4">GITA</p>
            <div className="inline-flex items-center mt-1">
              <IconNetherlands />
              <span className="ml-2 text-layout-gray-700">Netherlands</span>
            </div>
            <p className="mt-4">Stichting Consortium Green IT Regio Amsterdam – commonly known as Green IT Amsterdam, and abbreviated as GIT – is a network organisation of stakeholders focusing on Green IT, founded by the City Council of Amsterdam for the purpose of developing and investigating on non-profit basis Green IT solutions and possible policies. Green IT is a non-profit legal entity acting as association for the IT sector, the energy sector, user organisations and knowledge institutes.</p>
          </div>
          <div className="self-end">
            <hr className="my-6 border-layout-gray-300" />
            <div className="flex items-start">
              <div className="h-16 w-16 min-w-max rounded-full mr-4 overflow-hidden">
                <img
                  alt="Contact Image"
                  className="h-16 w-16 object-cover object-top"
                  src="/about-images/julie-chenadec.jpg"
                />
              </div>
              <div>
                <h3 className="font-semibold text-base leading-4">
                  Julie Chenadec
                </h3>
                <div className="text-layout-gray-500 text-sm mt-1">
                  WP6 Leader: Pilot demonstration and evaluation of the Design Concept
                </div>
                <div className="flex mt-2">
                  <EnvelopeSVG className="h-5 w-5 mr-2" />
                  <a
                    className="block text-sm underline"
                    href="mailto:julie.chenadec@sdialliance.org"
                  >
                    julie.chenadec@sdialliance.org
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="grid rounded bg-white p-6 text-layout-gray-base">
          <div>
            <img src={'../logos/sdia.png'} className="h-12" />
            <p className="font-bold text-2xl mt-4">SDIA</p>
            <div className="inline-flex items-center mt-1">
              <IconGermany />
              <span className="ml-2 text-layout-gray-700">Germany</span>
            </div>
            <p className="mt-4">The SDIA and its members are committed to creating digital infrastructure that has zero negative impact and is affordable for the next generation of innovators. The SDIA has been established as a Catalyst and Platform for driving inter-industry collaboration. Together with the member companies, individuals and governments we shape the standards and policies that are needed to realize our vision of economically and ecologically sustainable digital infrastructure.</p>
          </div>
          <div className="self-end">
            <hr className="my-6 border-layout-gray-300" />
            <div className="flex items-start">
              <div className="h-16 w-16 min-w-max rounded-full mr-4 overflow-hidden">
                <img
                  alt="Contact Image"
                  className="h-16 w-16 object-cover object-top"
                  src="/about-images/stephanie-leufgen.jpg"
                />
              </div>
              <div>
                <h3 className="font-semibold text-base leading-4">
                  Stephanie Leufgen
                </h3>
                <div className="text-layout-gray-500 text-sm mt-1">
                  WP8 Leader: Dissemination and communication
                </div>
                <div className="flex mt-2">
                  <EnvelopeSVG className="h-5 w-5 mr-2" />
                  <a
                    className="block text-sm underline"
                    href="mailto:stephanie.leufgen@sdialliance.org"
                  >
                    stephanie.leufgen@sdialliance.org
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="grid rounded bg-white p-6 text-layout-gray-base">
          <div>
            <img src={'../logos/bitnet.png'} className="h-12" />
            <p className="font-bold text-2xl mt-4">BitNet</p>
            <div className="inline-flex items-center mt-1">
              <IconTurkey />
              <span className="ml-2 text-layout-gray-700">Turkey</span>
            </div>
            <p className="mt-4">BitNet is real time analytics R&D Company developing interactive data simulation and visualization solutions for education, energy, health, defense, and manufacturing. Founded by seasoned engineers, designers and scientists with many years of experience in USA and Europe in September 2012; BitNet believes complex real-world problems requires on premise Big Data and HPC platforms. Therefore the company develops adaptive real-time interactive big data and HPC platforms for health, smart cities, traffic, and cyber security platforms.</p>
          </div>
          <div className="self-end">
            <hr className="my-6 border-layout-gray-300" />
            <div className="flex items-start">
              <div className="h-16 w-16 min-w-max rounded-full mr-4 overflow-hidden">
                <img
                  alt="Contact Image"
                  className="h-16 w-16 object-cover object-top"
                  src="/about-images/oguzhan.jpeg"
                />
              </div>
              <div>
                <h3 className="font-semibold text-base leading-4">
                  Oğuzhan Herkiloğlu
                </h3>
                <div className="text-layout-gray-500 text-sm mt-1">
                  Datacenter pilot - Turkey
                </div>
                <div className="flex mt-2">
                  <EnvelopeSVG className="h-5 w-5 mr-2" />
                  <a
                    className="block text-sm underline"
                    href="mailto:oguz@bitnet.com.tr"
                  >
                    oguz@bitnet.com.tr
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="max-w-screen-sm mx-auto px-4 mt-20 lg:mt-40 relative">
      <Newsletter />
    </div>
    
  </Layout>
)

export default NewsPage
